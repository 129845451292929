import { useEffect, useState } from "react"
import PortfolioList from "../portfolioList/PortfolioList"
import "./portfolio.css"
import { prototypePortfolio, vrPortfolio, animationPortfolio } from "../../data"
import ReactPlayer from "react-player"

export default function Portfolio() {
  const [selected, setSelected] = useState("prototypes")
  const [data, setData] = useState([])
  const List = [
    {
      id: "prototypes",
      title: "Game Prototypes"
    },
    {
      id: "animation",
      title: "Animation"
    }
  ]

  useEffect(() => {

    switch(selected){
      case "prototypes":
        setData(prototypePortfolio);
        break;
      case "animation":
        setData(animationPortfolio);
        break;
      default:
        setData(prototypePortfolio);
    }

  },[selected])
  return (
    <div className="portfolio" id="portfolio">
      <h1 className="portfolio-title">My Portfolio</h1>
      <ul>
        {List.map((item) =>(
          <PortfolioList title = {item.title} active = {selected === item.id} setSelected = {setSelected} id = {item.id}/>
        ))}
      </ul>
      <div className="portfolio-container">
        {data.map((d) => (
          <div className="portfolio-item">
            <h2 className="portfolio-item-title">{d.title}</h2>
            <ReactPlayer url={d.youtube} width='95%' height='200px'/>
            <a href={d.github} className="portfolio-git" target="_blank">Github Link</a>
          </div>
        ))}
      </div>
    </div>
  )
}