import "./topbar.css"

export default function Topbar() {
    return (
      <div className="topbar">
        <div className="topbar-left">
        <a href="#home" className="text">Portfolio Website</a>
        </div>
        <div className="topbar-right">
            <ul className="list">
                <li>
                    <a href="#home" className="text list-text">Home</a>
                </li>
                <li>
                    <a href="#skills" className="text list-text">Skills</a>
                </li>
                <li>
                    <a href="#portfolio" className="text list-text">Portfolio</a>
                </li>
                <li>
                    <a href="#contact" className="text list-text">Contact</a>
                </li>
            </ul>
        </div>
      </div>
    );
}