export const prototypePortfolio = [
    {
        id: 1,
        title: "Apple Picker",
        youtube: "https://youtu.be/ZlTNnkCG_HE",
        github: "https://github.com/mattwhitt1/ApplePickerPrototype"
    },
   
    {
        id: 2,
        title: "Kaboom",
        youtube: "https://youtu.be/Xy2fzKpwh9Q",
        github: "https://github.com/mattwhitt1/KaboomPrototype"
    },
    {
        id: 3,
        title: "Spaceship",
        youtube: "https://youtu.be/qb2Tpyg7eS4",
        github: "https://github.com/mattwhitt1/SpaceshipPrototype"
    }, {
        id: 4,
        title: "Brain Game",
        youtube: "https://youtu.be/VzoGxryY0vY",
        github: "https://github.com/mattwhitt1/BrainGamePrototype"
    },
]

export const animationPortfolio = [
    {
        id: 1,
        title: "Module Development",
        youtube: "https://youtu.be/08lhUQmUCA0",
        github: "https://github.com/mattwhitt1/ModularComponent"
    },
]