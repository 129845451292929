import "./skills.css"

export default function Skills() {
  return (
    <div className="skills" id="skills">
        <h1 className="skills-title">My Skills</h1>
        <h3 className="skills-desc">These are all the skills that I have learned in my classes. Specifics can be found on my resume in the contact page.</h3>
        <div className="skills-container">
            <div className="skills-item">
                <img src="img/Maya.png" alt="" className="skills-img"/>
                <h3 className="skills-text">Maya</h3>
            </div>
            <div className="skills-item">
                <img src="img/UnrealEngine.jpeg" alt="" className="skills-img"/>
                <h3 className="skills-text">Unreal Engine</h3>
            </div>
            <div className="skills-item">
                <img src="img/Unity.png" alt="" className="skills-img"/>
                <h3 className="skills-text">Unity</h3>
            </div>
            <div className="skills-item">
                <img src="img/CSharp.png" alt="" className="skills-img"/>
                <h3 className="skills-text">C#</h3>
            </div>
        </div>
    </div>
  )
}