import "./home.css";

export default function Home() {
  return (
    <div className="home" id="home">
      <div className="home-left">
        <div className="home-left-text">
          <h2 className="home-intro">Hey, I'm</h2>
          <h1 className="home-name">Matthew Whittington</h1>
          <h3 className="home-desc">I'm a full time student at the University of Missouri-Columbia majoring Computer Science with minors in Informational Technology and Digital Storytelling. I enjoy game development and I would like to become a full time game designer. My hobbies include playing video games, playing my instrument, and learning how to be a better game developer</h3>
        </div>
      </div>
      <div className="home-right">
        <div className="home-right-shadow">
        </div>
        <div className="home-right-image">
          <img src="img/CoverImage.png" alt="" className="image" />
        </div>
      </div>
    </div>
  );
}