import "./contact.css"
import emailjs from '@emailjs/browser';
import { useRef, useState } from "react"

export default function  Contact() {
    const formRef = useRef()
    const [done, setDone] = useState(false)

    const handleSubmit = (e)=>{
        e.preventDefault()

        emailjs.sendForm("service_is1nzbt", "template_q3j4ybr", formRef.current, "bcdYual0pgGTbGoSI")
      .then((result) => {
          console.log(result.text);
          setDone(true)
      }, (error) => {
          console.log(error.text);
      });
    }
  return (
    <div className="contact" id="contact">
        <div className="contact-left">
            <h1 className="contact-text">Contact Me!</h1>
            <div className="contact-item-container">
                <img src="img/PhoneIcon.png" alt="" className="contact-icon"/>
                <h2 className="contact-text">Phone #: +1 417-771-7187</h2>
            </div>
            <div className="contact-item-container">
                <img src="img/EmailIcon.png" alt="" className="contact-icon"/>
                <h2 className="contact-text">Email: mattheww9600@yahoo.com</h2>
            </div>
            <a href="download/MatthewWhittingtonResume.pdf" download className="contact-text">Download Resume</a>
        </div>
        <div className="contact-right">
            <h3 className="contact-text">Or send me a message here!</h3>
            <form ref={formRef} onSubmit={handleSubmit}>
                <input type="text" placeholder="Name" name="user_name"/>
                <input type="text" placeholder="Subject" name="user_subject"/>
                <input type="text" placeholder="Email" name="user_email"/>
                <textarea rows="5" placeholder="Message" name="message"/>
                <button>Submit</button>
                {done && "Thank You!"}
            </form>
        </div>
    </div>
  )
}